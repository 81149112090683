/* eslint-disable */

import axios from './axios';

// ----------------------------------------------------------------------

async function FakeRequest(time) {
  return new Promise((res) => setTimeout(res, time));
}

async function handleModifyProfile(data) {
  const response = await axios.put('/api/companies/user', data, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
  return response.data;
}

async function handleAddAgent(data) {
  const response = await axios.post('/api/companies/agent', data);
  return response.data;
}


async function handleAddMariageSurc(data) {
  const response = await axios.post('/api/companies/prime-mariage-surcussale', data);
  return response.data;
}

async function handleModifyMariageSurc(data) {
  const response = await axios.put('/api/companies/prime-mariage-surcussale', data);
  return response.data;
}


async function handleDeleteTransactionZero(agentid, transactionid) {
  const response = await axios.delete(
    `/api/companies/transaction-zero?agent_id=${agentid}&transaction_id=${transactionid}`
  );
  return response.data;
}

async function handleAddTransactionZero(data) {
  const response = await axios.post('/api/companies/transaction-zero', data);
  return response.data;
}


async function handleAddAgentGGenerate(data) {
  const response = await axios.post('/api/companies/generate-agent', data);
  return response.data;
}

async function handleModifyAgent(data) {
  const response = await axios.put('/api/companies/agent', data);
  return response.data;
}

async function handleDeleteAgent(data) {
  const response = await axios.delete('/api/companies/agent', { data });
  return response.data;
}

async function handleAddSupervisor(data) {
  const formData = new FormData();
  for (const key in data) {
    // Vérifiez et ajoutez des objets ou des tableaux de manière appropriée
    if (Array.isArray(data[key])) {
      data[key].forEach((item, index) => {
        formData.append(`${key}[${index}]`, item);
      });
    } else {
      formData.append(key, data[key]);
    }
  }
  try {
    const response = await axios.post('/api/companies/supervisor', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error:', error.response ? error.response.data : error.message);
    throw error;
  }
}

async function handleModifySupervisor(data) {
  const formData = new FormData();
  for (const key in data) {
    // Vérifiez et ajoutez des objets ou des tableaux de manière appropriée
    if (Array.isArray(data[key])) {
      data[key].forEach((item, index) => {
        formData.append(`${key}[${index}]`, item);
      });
    } else {
      formData.append(key, data[key]);
    }
  }
  try {
    const response = await axios.put('/api/companies/supervisor', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error:', error.response ? error.response.data : error.message);
    throw error;
  }
}


async function handleDeleteSupervisor(data) {
  const response = await axios.delete('/api/companies/supervisor', { data });
  return response.data;
}

async function handleAddMessage(data) {
  const response = await axios.post('/api/companies/messages', data);
  return response.data;
}

async function handleModifyMessage(data) {
  const response = await axios.put('/api/companies/messages', data);
  return response.data;
}

async function handleDeleteMessage(data) {
  const response = await axios.delete('/api/companies/messages', { data });
  return response.data;
}

async function handleAddCompanyPayment(data) {
  const response = await axios.post('/api/companies/historics-payment', data);
  return response.data;
}

async function handleModifyCompanyPayment(data) {
  const response = await axios.put('/api/companies/historics-payment', data);
  return response.data;
}

async function handleDeleteCompanyPayment(data) {
  const response = await axios.delete('/api/companies/historics-payment', { data });
  return response.data;
}

async function handleAddAuto(data) {
  const response = await axios.post('/api/generals/tirage-auto', data);
  return response.data;
}

async function handleAddCountry(data) {
  const response = await axios.post('/api/generals/countries', data, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
  return response.data;
}

async function handleModifyCountry(data) {
  const response = await axios.put('/api/generals/countries', data, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
  return response.data;
}

async function handleDeleteCountry(data) {
  const response = await axios.delete('/api/generals/delete-country', { data });
  return response.data;
}

async function handleAddCompagnie(data) {
  const response = await axios.post('/api/generals/compagnies', data, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
  return response.data;
}

async function handleModifyCompagnie(data) {
  const response = await axios.put('/api/generals/compagnies', data, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
  return response.data;
}

async function handleDeleteCompagnie(data) {
  const response = await axios.delete('/api/generals/delete-company', { data });
  return response.data;
}

async function handleAddSurcussale(data) {
  const response = await axios.post('/api/companies/surcussale', data);
  return response.data;
}

async function handleAddLotto(data) {
  const response = await axios.post('/api/companies/lotto', data);
  return response.data;
}

async function handleModifyLotto(data) {
  const response = await axios.put('/api/companies/lotto', data);
  return response.data;
}

async function handleDeleteLotto(data) {
  const response = await axios.delete('/api/companies/lotto', { data });
  return response.data;
}

async function handleModifySurcussale(data) {
  const response = await axios.put('/api/companies/surcussale', data);
  return response.data;
}

async function handleAddTirage(data) {
  const response = await axios.post('/api/companies/tirage', data);
  return response.data;
}

async function handleModifyTirage(data) {
  const response = await axios.put('/api/companies/tirage', data);
  return response.data;
}

async function handleDeleteTirage(tirageId) {
  const response = await axios.delete(`/api/companies/tirage?tirageId=${tirageId}`);
  return response.data;
}

async function handleAddInfoTirage(data) {
  const response = await axios.post('/api/companies/info-tirage', data);
  return response.data;
}

async function handleAddLimiteFiche(data) {
  const response = await axios.post('/api/games/add-limite-fiche', data);
  return response.data;
}

async function handleModifyInfoTirage(data) {
  const response = await axios.put('/api/companies/info-tirage', data);
  return response.data;
}

async function handleModifyLimiteFiche(data) {
  const response = await axios.put('/api/games/update-limite-fiche', data);
  return response.data;
}

async function handleDeleteInfoTirage(data) {
  const response = await axios.delete('/api/companies/info-tirage', { data });
  return response.data;
}

async function handleAddTypeGame(data) {
  const response = await axios.post('/api/companies/type-games', data);
  return response.data;
}

async function handleModifyTypeGame(data) {
  const response = await axios.put('/api/companies/type-games', data);
  return response.data;
}

async function handleAddTypeBoule(data) {
  const response = await axios.post('/api/companies/type-boules', data);
  return response.data;
}

async function handleModifyTypeBoule(data) {
  const response = await axios.put('/api/companies/type-boules', data);
  return response.data;
}

async function handleAddTypeGameAgent(data) {
  const response = await axios.post('/api/companies/type-games-agent', data);
  return response.data;
}

async function handleModifyTypeGameAgent(data) {
  const response = await axios.put('/api/companies/type-games-agent', data);
  return response.data;
}

async function handleAddTypeBouleAgent(data) {
  const response = await axios.post('/api/companies/type-boules-agent', data);
  return response.data;
}

async function handleModifyTypeBouleAgent(data) {
  const response = await axios.put('/api/companies/type-boules-agent', data);
  return response.data;
}

async function handleAddBouleBlock(data) {
  const response = await axios.post('/api/companies/boules-block', data);
  return response.data;
}

async function handleModifyBouleBlock(data) {
  const response = await axios.put('/api/companies/boules-block', data);
  return response.data;
}

async function handlePostTirageConf(data) {
  const response = await axios.post('/api/companies/prime-tirage', data);
  return response.data;
}

async function handlePostAgentConf(data) {
  const response = await axios.post('/api/companies/prime-agent', data);
  return response.data;
}

async function handleDeletePrime(data, type) {
  console.log("Prime-type: ", type, data);
  const response = await axios.delete(`/api/companies/prime-${type}`, {data});
  return response.data;
}

async function handleUpdatePrimeConf(data) {
  const response = await axios.put('/api/companies/prime', data);
  return response.data;
}

async function handleUpdateTirageConf(data) {
  const response = await axios.put('/api/companies/prime-tirage', data);
  return response.data;
}

async function handleUpdateAgentConf(data) {
  const response = await axios.put('/api/companies/prime-agent', data);
  return response.data;
}

async function handleUpdateFiche(data) {
  const response = await axios.put('/api/companies/fiche', data);
  return response.data;
}

async function handleDeleteTypeGames(data) {
  const response = await axios.delete('/api/companies/type-games', { data });
  return response.data;
}

async function handleDeleteTypeGameAgents(data) {
  const response = await axios.delete('/api/companies/type-games-agent', { data });
  return response.data;
}

async function handleDeleteTypeBoules(data) {
  const response = await axios.delete('/api/companies/type-boules', { data });
  return response.data;
}

async function handleDeleteTypeBouleAgents(data) {
  const response = await axios.delete('/api/companies/type-boules-agent', { data });
  return response.data;
}

async function handleDeleteSurcussale(data) {
  const response = await axios.delete('/api/companies/surcussale', { data });
  return response.data;
}

async function handleAddTransaction(data) {
  const response = await axios.post('/api/companies/transactions', data);
  return response.data;
}

async function handleChooseCompany(data) {
  const response = await axios.post('/api/generals/company', data);
  return response.data;
}

async function handleChooseCountry(data) {
  const response = await axios.post('/api/generals/country', data);
  return response.data;
}


async function handleAddIntervallma(data) {
  const response = await axios.post('/api/companies/add-interval-ma', data);
  return response.data;
}

async function handleModifyIntervalMa(data) {
  const response = await axios.put('/api/companies/edit-interval-ma', data);
  return response.data;
}

async function handleAddIntervallmaSurcussale(data) {
  const response = await axios.post('/api/companies/add-interval-ma-surcu', data);
  return response.data;
}

async function handleModifyIntervalMaSurcussale(data) {
  const response = await axios.put('/api/companies/edit-interval-ma-surcu', data);
  return response.data;
}



export default {
  handleAddIntervallmaSurcussale,
  handleModifyIntervalMaSurcussale,
  handleModifyIntervalMa,
  handleAddIntervallma,
  FakeRequest,
  handleModifyProfile,
  handleAddAgent,
  handleModifyAgent,
  handleDeleteAgent,
  handleAddSupervisor,
  handleModifySupervisor,
  handleDeleteSupervisor,
  handleAddSurcussale,
  handleModifySurcussale,
  handleAddLotto,
  handleModifyLotto,
  handleDeleteLotto,
  handleAddTirage,
  handleModifyTirage,
  handleDeleteTirage,
  handleAddInfoTirage,
  handleModifyInfoTirage,
  handleDeleteInfoTirage,
  handleAddBouleBlock,
  handleModifyBouleBlock,
  handlePostTirageConf,
  handlePostAgentConf,
  handleUpdatePrimeConf,
  handleUpdateTirageConf,
  handleUpdateAgentConf,
  handleDeletePrime,
  handleAddTypeGame,
  handleModifyTypeGame,
  handleAddTypeBoule,
  handleModifyTypeBoule,
  handleAddTypeGameAgent,
  handleModifyTypeGameAgent,
  handleAddTypeBouleAgent,
  handleModifyTypeBouleAgent,
  handleUpdateFiche,
  handleDeleteTypeGames,
  handleDeleteTypeGameAgents,
  handleDeleteTypeBoules,
  handleDeleteTypeBouleAgents,
  handleDeleteSurcussale,
  handleAddTransaction,
  handleChooseCompany,
  handleChooseCountry,
  handleAddMessage,
  handleModifyMessage,
  handleDeleteMessage,
  handleAddCountry,
  handleModifyCountry,
  handleDeleteCountry,
  handleAddCompagnie,
  handleModifyCompagnie,
  handleDeleteCompagnie,
  handleAddCompanyPayment,
  handleModifyCompanyPayment,
  handleDeleteCompanyPayment,
  handleAddAuto,
  handleAddAgentGGenerate,
  handleAddLimiteFiche,
  handleModifyLimiteFiche,
  handleDeleteTransactionZero,
  handleAddTransactionZero,
  
  handleAddMariageSurc,
  handleModifyMariageSurc
};
