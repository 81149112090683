// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/dashboard';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login')
};

export const PATH_PAGE = {
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  about: '/about-us',
  contact: '/contact-us',
  faqs: '/faqs',
  page404: '/404',
  page500: '/500'
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  fiche_view: (refCode) => `/fiche-view/${refCode}`,
  general: {
    app: path(ROOTS_DASHBOARD, '/app')
  },
  borlette: {
    root: path(ROOTS_DASHBOARD, '/borlette'),
    blocage_boule: path(ROOTS_DASHBOARD, '/borlette/blocage-boule'),
    add_blocage_boule: path(ROOTS_DASHBOARD, '/borlette/blocage-boule/new'),
    lotto: path(ROOTS_DASHBOARD, '/borlette/lotto'),
    add_lotto: path(ROOTS_DASHBOARD, '/borlette/lotto/new'),
    statistique: path(ROOTS_DASHBOARD, '/borlette/statistiques'),
    list: path(ROOTS_DASHBOARD, '/borlette/list-fiches'),
    list_fgagnant: path(ROOTS_DASHBOARD, '/borlette/list-fiches-gagnants'),
    list_fpayant: path(ROOTS_DASHBOARD, '/borlette/list-fiches-payant'),
    list_fsupp: path(ROOTS_DASHBOARD, '/borlette/list-fiches-supprimer')
  },
  configuration_borlette: {
    root: path(ROOTS_DASHBOARD, '/borlette-configuration'),
    type_game: path(ROOTS_DASHBOARD, '/borlette-configuration/limite-game'),
    add_type_game: path(ROOTS_DASHBOARD, '/borlette-configuration/limite-game/new'),
    type_game_agent: path(ROOTS_DASHBOARD, '/borlette-configuration/limite-game-per-agent'),
    add_type_game_agent: path(ROOTS_DASHBOARD, '/borlette-configuration/limite-game-per-agent/new'),
    type_boule: path(ROOTS_DASHBOARD, '/borlette-configuration/limite-boule'),
    add_type_boule: path(ROOTS_DASHBOARD, '/borlette-configuration/limite-boule/new'),
    type_boule_agent: path(ROOTS_DASHBOARD, '/borlette-configuration/limite-boule-per-agent'),
    add_type_boule_agent: path(ROOTS_DASHBOARD, '/borlette-configuration/limite-boule-per-agent/new'),
    intervalMa: path(ROOTS_DASHBOARD, '/borlette-configuration/interval-ma'),
    intervalMa_add: path(ROOTS_DASHBOARD, '/borlette-configuration/interval-ma/new'),
    intervalMaSur: path(ROOTS_DASHBOARD, '/borlette-configuration/interval-ma-sur'),
    intervalMa_sur_add: path(ROOTS_DASHBOARD, '/borlette-configuration/interval-ma-sur/new')
    
  },
  tirage: {
    root: path(ROOTS_DASHBOARD, '/tirage'),
    newTirage: path(ROOTS_DASHBOARD, '/tirage/new'),
    newTirageAuto: path(ROOTS_DASHBOARD, '/tirage/automatique'),
    list: path(ROOTS_DASHBOARD, '/tirage/list'),
    configuration_list: path(ROOTS_DASHBOARD, '/tirage/configuration/generale/list'),
    configuration: path(ROOTS_DASHBOARD, '/tirage/configuration/generale'),
    configuration_type: (type) => path(ROOTS_DASHBOARD, `/tirage/configuration/${type}`),
    edit_configuration: (type, configurationId) =>
      path(ROOTS_DASHBOARD, `/tirage/configuration/${type}/${configurationId}/edit`),
    add_configuration: (type) => path(ROOTS_DASHBOARD, `/tirage/configuration/${type}/new`),
    info_tirage: path(ROOTS_DASHBOARD, '/tirage/infotirage'),
    add_info_tirage: path(ROOTS_DASHBOARD, '/tirage/infotirage/new')
  },
  rapport: {
    root: path(ROOTS_DASHBOARD, '/rapports/root'),
    journalier: path(ROOTS_DASHBOARD, '/rapports/journalier'),
    agent: path(ROOTS_DASHBOARD, '/rapports/agent')
  },
  transaction: {
    root: path(ROOTS_DASHBOARD, '/transactions'),
    new: path(ROOTS_DASHBOARD, '/transactions/new')
  },
  agent: {
    root: path(ROOTS_DASHBOARD, '/agent'),
    list: path(ROOTS_DASHBOARD, '/agent/list'),
    newAgent: path(ROOTS_DASHBOARD, '/agent/new')
  },

  limiteFiche: {
    root: path(ROOTS_DASHBOARD, '/limiteFiche'),
    list: path(ROOTS_DASHBOARD, '/limiteFiche/list'),
    newLimit: path(ROOTS_DASHBOARD, '/limiteFiche/new')
  },
  company: {
    root: path(ROOTS_DASHBOARD, '/company'),
    list: path(ROOTS_DASHBOARD, '/company/list'),
    newCompany: path(ROOTS_DASHBOARD, '/company/new'),
    companyPayment: path(ROOTS_DASHBOARD, '/company/payment'),
    companyFactu: path(ROOTS_DASHBOARD, '/company/facturation'),
    companyPayPayment: path(ROOTS_DASHBOARD, '/company/pay-facturation'),
    newCompanyPayment: path(ROOTS_DASHBOARD, '/company/payment/new')
  },
  generale: {
    root: path(ROOTS_DASHBOARD, '/generale'),
    list: path(ROOTS_DASHBOARD, '/generale/list'),
    newGenerale: path(ROOTS_DASHBOARD, '/generale/new')
  },
  supervisor: {
    root: path(ROOTS_DASHBOARD, '/supervisor'),
    list: path(ROOTS_DASHBOARD, '/supervisor/list'),
    newSupervisor: path(ROOTS_DASHBOARD, '/supervisor/new')
  },
  surcussale: {
    root: path(ROOTS_DASHBOARD, '/surcussale'),
    list: path(ROOTS_DASHBOARD, '/surcussale/list'),
    newSurcussale: path(ROOTS_DASHBOARD, '/surcussale/new')
  },
  
  mariageSurcussale: {
    root: path(ROOTS_DASHBOARD, '/mariageSurcussale'),
    list: path(ROOTS_DASHBOARD, '/mariageSurcussale/list'),
    newMariageSurc: path(ROOTS_DASHBOARD, '/mariageSurcussale/new')
  },
  user: {
    root: path(ROOTS_DASHBOARD, '/user'),
    account: path(ROOTS_DASHBOARD, '/user/account'),
    generateAgent:path(ROOTS_DASHBOARD, '/user/generate-agent'),
    
    tracabilite_company: path(ROOTS_DASHBOARD, '/user/tracabilite-company'),
    tracabilite_agent: path(ROOTS_DASHBOARD, '/user/tracabilite-agent'),
    tracabilite_supervisor: path(ROOTS_DASHBOARD, '/user/tracabilite-supervisor'),
    promote: path(ROOTS_DASHBOARD, '/user/message-push'),
    newPromote: path(ROOTS_DASHBOARD, '/user/message-push/new'),
    country: path(ROOTS_DASHBOARD, '/user/pays'),
    newCountry: path(ROOTS_DASHBOARD, '/user/pays/new')
  }
};
